import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import axios from "axios";
import dayjs from 'dayjs';
import {Spin} from "antd";

const ReservationsByDate = () => {
    const [reservations, setReservations] = useState([]);
    const [loading, setLoading] = useState(true); // Добавляем состояние для отслеживания загрузки

    const getIdFromURL = () => {
        const url = window.location.pathname;
        const regex = /\/admin\/(\w+)/;
        const match = url.match(regex);
        if (match && match[1]) {
            return match[1];
        }
        return null;
    };

    useEffect(() => {
        const id = getIdFromURL();
        if (id) {
            getPersonsPerDay(id);
        }
    }, []);

    const getPersonsPerDay = (_id) => {
        setLoading(true); // Устанавливаем состояние загрузки в true перед отправкой запроса
        axios.get(`https://back.terkorbeke-restaurant.be/admin-get-reservations-persons/${_id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        }).then((response) => {
            setReservations(response.data);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false); // После завершения запроса устанавливаем состояние загрузки в false
        });
    };

    return (
        <main className='main_admin'>
            <div className='container_admin'>
                <div className='wrap_admin'>
                    <div className="nav-header_link">
                        <NavLink to="/admin">
                            <span>back</span>
                        </NavLink>
                    </div>
                    <h3>Reservations by date</h3>
                    {loading ? ( // Показываем лоадер, если состояние загрузки равно true
                        <Spin className='spin_center'/>
                    ) : (
                        <div className='reservations'>
                            <table>
                                <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>User</th>
                                    <th>email</th>
                                    <th>person</th>
                                </tr>
                                </thead>
                                <tbody>
                                {reservations.map((reservation, index) => (
                                    <tr key={index}>
                                        <td>{dayjs.utc(reservation.date).format('h:mm a')}</td>
                                        <td>{reservation.name}</td>
                                        <td>{reservation.email}</td>
                                        <td>{reservation.person}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </main>
    );
};

export default ReservationsByDate;
