import React from 'react';
import Layout from "../components/layout/layout";

const OverOns = () => {
    return (
        <>
            <Layout/>
            <main className="main">
                <div className="top-over-ons"/>
                <section className="middle_title">
                    <div className="top_title">
                        <h1>Over ons</h1>
                    </div>
                    <p>Onze chef, die al een jarenlange ervaring heeft, maakt al de gerechten klaar in onze eigen keuken en
                        dit enkel met verse producten</p>
                    <p>Al onze vlees en visgerechten worden op de grill klaargemaakt, zo tovert hij met liefde en passie
                        een heerlijk bord voor je klaar</p>
                </section>
            </main>
        </>
    );
};

export default OverOns;