'use client';
import React, {useEffect, useRef} from 'react';
import Layout from "../components/layout/layout";
import {DatePicker, Form, Input, InputNumber, Button, TimePicker, notification} from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {MobileDateTimePicker} from '@mui/x-date-pickers/MobileDateTimePicker';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

dayjs.extend(utc);


const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

const Reserveren = () => {

    const formRef = useRef(null); // Создаем реф для доступа к форме

    const onFinish = async (values) => {
        try {
            // Конвертируем дату в UTC формат перед отправкой на сервер
            const dateUTC = dayjs.utc(values.user.date).toISOString();
            const updatedValues = {
                ...values,
                user: {
                    ...values.user,
                    date: dateUTC,
                },
            };
            const response = await axios.post(`https://back.terkorbeke-restaurant.be/reserveren`, updatedValues.user);
            if (response.status === 200) {
                // Очищаем поля формы после успешной отправки
                formRef.current.resetFields();
                // message.success('Check and confirm your email');
                notification.success({
                    message: 'Proceed to your email',
                    description: response.data.message,
                    duration: 0,
                });

            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <>
            <Layout/>
            <main className="main">
                <section className="reserveren_section"/>

                <section className="reserveren">
                    <h1>Reserveren</h1>
                    {/* <p>Reserveer een tafel</p> */}
                    <Form
                        {...layout}
                        ref={formRef}
                        name="nest-messages"
                        onFinish={onFinish}
                        style={{
                            maxWidth: 600,
                        }}
                        validateMessages={validateMessages}
                    >
                        <Form.Item
                            name={['user', 'name']}
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name={['user', 'email']}
                            label="Email"
                            rules={[
                                {
                                    type: 'email',
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name={['user', 'phone']}
                            label="Phone"
                            rules={[
                                {
                                    type: 'phone',
                                    required: false,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name={['user', 'date']}
                            label="Date"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <div style={{border: 'solid 1px #d9d9d9', borderRadius: 5, padding: 'auto'}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDateTimePicker
                                        onChange={(newDate) => {
                                            // Добавляем 1 час к выбранному времени, чтобы скорректировать на разницу во времени
                                            const adjustedDate = dayjs(newDate).add(1, 'hour');

                                            // Конвертируем скорректированное время в UTC формат
                                            const utcDate = adjustedDate.toISOString();
                                            // Обновить значение поля формы с конвертированным временем
                                            formRef.current.setFieldsValue({
                                                user: {
                                                    ...formRef.current.getFieldValue('user'),
                                                    date: utcDate,
                                                },
                                            });
                                        }}
                                        ampm
                                        defaultValue={dayjs().set('hour', 11).add(1, 'hour')}
                                        minDate={dayjs().startOf('day')}
                                        minutesStep={30}
                                    />
                                </LocalizationProvider>
                            </div>
                        </Form.Item>

                        <Form.Item
                            name={['user', 'person']}
                            label="Person"
                            rules={[
                                {
                                    type: 'number',
                                    min: 1,
                                    max: 100,
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                ...layout.wrapperCol,
                                offset: 8,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </section>
            </main>
        </>
    );
};

export default Reserveren;
