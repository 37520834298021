import React, {memo} from 'react';
import {NavLink} from "react-router-dom";

const NavHeader = () => {
    return (
        <div className="nav-header">

            <div className="nav-header_logo">
                <NavLink to="/">
                    <img
                        src="/images/terkorbeke-svg-2.webp"
                        alt="Logo"
                        loading='lazy'
                    />
                </NavLink>
            </div>

            <div className="nav-header_links">
                <div className="nav-header_link">
                    <NavLink to="/">
                        <span>Home</span>
                    </NavLink>
                </div>

                <div className="nav-header_link">
                    <NavLink to="/over-ons">
                        <span>Over ons</span>
                    </NavLink>
                </div>

                <div className="nav-header_link ">
                    <NavLink to="/menu">
                        <span>Menu</span>
                    </NavLink>
                </div>

                <div className="nav-header_link ">
                    <NavLink to="/lunch">
                        <span>Lunch</span>
                    </NavLink>
                </div>

                <div className="nav-header_link">
                    <NavLink to="/event">
                        <span>Event</span>
                    </NavLink>
                </div>
                
                <div className="nav-header_link">
                    <NavLink to="/gallery">
                        <span>Gallery</span>
                    </NavLink>
                </div>
                <div className="nav-header_link">
                    <NavLink to="/contact">
                        <span>Contact</span>
                    </NavLink>
                </div>
                <div className="nav-header_link">
                    <NavLink to="/reserveren">
                        <span>Reserveren</span>
                    </NavLink>
                </div>

            </div>


            <div className="nav-header_bottom">
            <div style={{color:'#ae975f',fontSize:18,textAlign:'center',marginBottom:40}}>
                <p style={{margin:0}}>Open 7/7</p>
                <p style={{margin:0}}>11:30 - 22:00</p>
            </div>
                <a href="https://www.facebook.com/terkorbeke" target="_blank" rel="noreferrer">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height="20"
                         viewBox="0 0 14.9 32"
                    >
                        <path d="M14.9,10.4h-5V7.1c0-1.2,0.8-1.5,1.4-1.5c0.6,0,3.5,0,3.5,0V0.1l-4.9,0c-5.4,0-6.7,4.1-6.7,6.7v3.6H0.2V16h3.1
	c0,7.2,0,15.9,0,15.9h6.6c0,0,0-8.8,0-15.9h4.4L14.9,10.4z" fill="#ffae1b"></path>
                    </svg>
                </a><br/>
                <span>Contact:</span><br/>
                <span>Oudebaan 106</span><br/>
                <span>3360 Bierbeek</span><br/>
                <a href="tel: +32 499 35 97 79">+32 499 35 97 79</a><br/>
                <a href="mailto: info@terkorbeke-restaurant.be">info@terkorbeke-restaurant.be</a>
            </div>

        </div>
    );
};

export default memo(NavHeader);