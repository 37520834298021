import React from 'react';
import { Route, Routes } from 'react-router-dom'
import Home from "./routes/home";
import Contact from "./routes/contact";
import OverOns from "./routes/over-ons";
import Menu from "./routes/menu";
import Gallery from "./routes/gallery";
import Lunch from "./routes/lunch";
import Admin from "./routes/admin";
import Reserveren from "./routes/reserveren";
import ConfirmReservation from "./routes/confirm";
import ReservationsByDate from "./routes/reservationsByDate";
import Event from "./routes/event";

const App = () => {
    return (
        <>
            <Routes>
                <Route exact path='/' element={<Home />}/>
                <Route exact path='/over-ons' element={<OverOns />}/>
                <Route exact path='/menu' element={<Menu />}/>
                <Route exact path='/lunch' element={<Lunch />}/>
                <Route exact path='/event' element={<Event />}/>
                <Route exact path='/gallery' element={<Gallery />}/>
                <Route exact path='/contact' element={<Contact />}/>
                <Route exact path='/reserveren' element={<Reserveren />}/>
                <Route exact path='/admin' element={<Admin />}/>
                <Route path='/confirm/:token' element={<ConfirmReservation />} />
                <Route path='/admin/:_id' element={<ReservationsByDate />} />
            </Routes>
        </>
    );
};

export default App;