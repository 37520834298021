import React from 'react';
import HeaderTop from "./HeaderTop";
import NavHeader from "./navHeader";

const Layout = () => {
    // public/images/moederdag.webp
    return (
        <>
            <div className="header">
                <div className="container-header">
                    <NavHeader/>
                </div>
            </div>
            <HeaderTop/>

        </>
    );
};

export default Layout;