import React from 'react';
import Layout from "../components/layout/layout";
import Album from "../components/Album";

const Gallery = () => {
    return (
        <>
            <Layout/>
            <main className="main">
                <section className="top-gallery">
                    <div className="gallery_title">
                        <h1>Gallery</h1>
                    </div>
                    <div>
                        <Album/>
                    </div>
                </section>

            </main>
        </>
    );
};

export default Gallery;