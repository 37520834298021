import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Input, Button, Spin} from 'antd';
import {NavLink} from "react-router-dom";

// ${process.env.REACT_APP_SERVER_URL}
// https://back.terkorbeke-restaurant.be

const Admin = () => {
    const [auth, setAuth] = useState(false)
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const [loading, setLoading] = useState(false)
    // Проверяем наличие токена в localStorage при загрузке страницы
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            setLoading(true)
            // Проверяем валидность токена на сервере
            axios.post(`https://back.terkorbeke-restaurant.be/validate-token`, {token})
                .then(() => {
                    // Если токен действителен, устанавливаем флаг авторизации
                    setAuth(true);
                    setLoading(false)
                })
                .catch((error) => {
                    console.log("Error caught:", error);
                    if (error.response && error.response.status === 401) {
                        // If the token is invalid, remove it from localStorage
                        localStorage.removeItem('accessToken');
                    }
                    setLoading(false); // Устанавливаем loading в false
                });
        }
    }, []);

    const handleSubmit = () => {
        axios.post(`https://back.terkorbeke-restaurant.be/admin-login`, {
            name,
            password
        }).then((response) => {
            // Сохраняем токен в localStorage
            localStorage.setItem('accessToken', response.data.accessToken);
            setAuth(true);
        }).catch((error) => {
            console.log(error);
            if (error.message === 'Network Error') {
                setError('Network Error');
            } else {
                setError('Wrong name or password');
            }
        });
    };


    const [reservations, setReservations] = useState([])
    const sortedReservations = reservations.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
    });

    useEffect(() => {
        if (!auth) {
            return
        } else {
            const getAllReservations = () => {
                axios.get(`https://back.terkorbeke-restaurant.be/admin-get-reservations`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    }
                ).then((response) => {
                        // console.log(response)
                        setReservations(response.data)
                    }
                ).catch((error) => {
                        console.log(error)
                    }
                )
            }
            getAllReservations();
        }
    }, [setReservations, auth])


    const logout = () => {
        localStorage.removeItem('accessToken');
        setAuth(false);
    }
    if (loading) {
        return (
            <Spin className='spin_center'/>
        )
    }
    if (!auth) {
        return (
            <>
                <main className='main_admin'>
                    <div className='container_admin'>
                        <div className='wrap_admin login'>
                            <div className="nav-header_link">
                                <NavLink to="/">
                                    <span>Home</span>
                                </NavLink>
                            </div>
                            <h3>Admin page</h3>

                            <Input
                                type="text"
                                placeholder='name'
                                onChange={(e) => {
                                    setName(e.target.value)
                                    setError('')
                                }}
                            />
                            <br/>
                            <Input
                                type="password"
                                placeholder='password'
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                    setError('')
                                }}
                            />
                            <br/>
                            <Button className='button_login' onClick={() => handleSubmit()}>Log in</Button>
                            <br/>
                            <div className='error'>{error}</div>
                        </div>
                    </div>
                </main>
            </>
        )
    } else {
        return (
            <>
                <main className='main_admin'>
                    <div className='container_admin'>
                        <div className='wrap_admin'>
                            <h2>Admin page</h2>
                            <Button onClick={() => logout()}>Log out</Button>
                            {sortedReservations.map((reservation, index) => {
                                const reservationDate = new Date(reservation.date);
                                const day = reservationDate.getDate().toString().padStart(2, '0');
                                const month = (reservationDate.getMonth() + 1).toString().padStart(2, '0');
                                const year = reservationDate.getFullYear();
                                const formattedDate = `${day}-${month}-${year}`;
                                return (
                                    <NavLink key={index}
                                             to={`/admin/${reservation._id}`}
                                             activeClassName='active'
                                    >
                                        <div className='admin_person'
                                             style={{
                                                 border: 'solid 1px #d2d2d2',
                                                 margin: '10px 0',
                                                 padding: 20,
                                                 borderRadius: 5
                                             }}
                                        >
                                            {/* Отображение отформатированной даты */}
                                            <p>Day - {formattedDate}</p>
                                            <p>free seats - {reservation.availableSeats}</p>
                                            <p>reserved seats - {100 - reservation.availableSeats}</p>
                                        </div>
                                    </NavLink>
                                );
                            })}
                        </div>
                    </div>
                </main>
            </>
        )
    }
}
//vasilich
//14/02TerKorbeke11#08
export default Admin
