import React, { memo } from 'react';

import {InView} from "react-intersection-observer";
import {animateVariants} from "./animateVariants";

const Album = () => {
    const photosHorizon = [

        {
            src: "/images/gallery/1.jpg",
            id: 1,
            title: 'description photo 1'
        },
        {
            src: "/images/gallery/2.jpg",
            id: 2,
            title: 'description photo 2'
        },
        {
            src: "/images/gallery/3.jpg",
            id: 3,
            title: 'description photo 3'
        },
        {
            src: "/images/gallery/4.webp",
            id: 4,
            title: 'description photo 4'
        },
        {
            src: "/images/gallery/5.webp",
            id: 5,
            title: 'description photo 5'
        },
        {
            src: "/images/gallery/12.webp",
            id: 12,
            title: 'description photo 12'
        },
        {
            src: "/images/gallery/10.webp",
            id: 10,
            title: 'description photo 10'
        },
        {
            src: "/images/gallery/11.webp",
            id: 11,
            title: 'description photo 11'
        },

        {
            src: "/images/gallery/13.webp",
            id: 13,
            title: 'description photo 13'
        },
        {
            src: "/images/gallery/14.webp",
            id: 14,
            title: 'description photo 14'
        },
        {
            src: "/images/gallery/15.webp",
            id: 15,
            title: 'description photo 15'
        },
        {
            src: "/images/gallery/16.webp",
            id: 16,
            title: 'description photo 16'
        },
        {
            src: "/images/gallery/17.webp",
            id: 17,
            title: 'description photo 17'
        },
        {
            src: "/images/gallery/18.webp",
            id: 18,
            title: 'description photo 18'
        },
    ]
    return (
        <div className="album">
            {photosHorizon.map((i) => (
                <InView key={i.id}>
                    {({inView, ref}) => (
                        <div className="album_item">
                            <img className="horizon"
                                 src={i.src} key={i.id} alt={i.title}
                                 variants={animateVariants.divider}
                                 initial="hidden"
                                 animate={inView ? "show" : "hidden"}
                                 exit="exit"
                                 ref={ref}
                            />
                        </div>
                    )}
                </InView>
            ))}
        </div>
    );
    
};

export default memo(Album);