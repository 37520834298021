import React, { useState } from 'react';
import Layout from "../components/layout/layout";


const Event = () => {


    return (
        <>
            <Layout />
            <main className="main">
                <section className="event" />

                <section className="event_middle">
                    <h1>Events</h1>
                    <div className="event_items">
                        <div className="event_item">
                            <img
                                src="/images/event_3.jpg"
                                alt="Event"
                                loading='lazy'
                            />
                            {/* <h2>Barbecue</h2>
                            <h3>2 juni 2024, om 17.00</h3>
                            <p>U bent uitgenodigd voor een avond vol vreugde en heerlijk eten tegen een voordelige prijs in ons restaurant Terkorbeke, drankjes zijn niet inbegrepen. Haast u om te reserveren, de plaatsen zijn beperkt. </p> */}
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default Event;
