import React, {useEffect, useState} from 'react';
import Layout from "../components/layout/layout";
import axios from 'axios';

const Confirm = () => {
    const [message, setMessage] = useState('');
//
    useEffect(() => {
        const confirmReservation = async () => {
            try {
                // Получаем токен из URL
                const url = window.location.href;
                const token = url.split('/').pop();

                // Отправляем запрос на сервер для подтверждения резервации
                const response = await axios.get(`https://back.terkorbeke-restaurant.be/confirm/${token}`);
                // Проверяем, есть ли поле error в ответе
                if (response.data.error) {
                    // Если есть, устанавливаем сообщение об ошибке
                    setMessage(response.data.error);
                } else {
                    // Если нет, устанавливаем сообщение о подтверждении резервации
                    setMessage(response.data.message);
                }
            } catch (error) {
                console.error(error);
                setMessage(error.message);
            }
        };

        confirmReservation();
    }, []); // Пустой массив зависимостей, чтобы хук запускался только один раз при монтировании компонента

    return (
        <>
            <Layout/>
            <main className="main">
                <section className="top"/>
                <section className="reserveren">
                    <h1>{message}</h1>
                </section>
            </main>
        </>
    );
};

export default Confirm;
