import React from 'react';
import Layout from "../components/layout/layout";
import { Image } from "antd";
import useWindowSize from "../components/useWindowSize";

// public\images\moederdag_mobile.webp
const Home = () => {
    const {width} = useWindowSize();
    console.log(`width: ${width}`);
    return (
        <>
            <Layout/>
            <main className="main">
                <section className="top"/>  

                <section className="home-middle">
                {/* <div className='event_stick'>
                    <Image 
                    loading='lazy'
                    src={width < 768 ? "/images/moederdag_mobile.webp" : "/images/moederdag.webp"}
                    alt="Event"
                    />
                </div> */}
                    <h1>Welkom in ons vernieuwd concept van brasserie/restaurant “Ter Korbeke”</h1>
                    <h2>Rustig gelegen in Korbeek-Lo (Bierbeek) waar we tevens ook beschikken we over een ruime parking.</h2>
                    <h2>Bij ons kan je elke dag terecht voor een (h) eerlijke dagschotel aan zeer democratische prijzen alsook
                        huisgemaakte gerechten a la carte</h2>
                    <h2>Eveneens ook voor een frisse pint of een koffie met een lekker dessertje.</h2>
                    <h2>Bij mooi weer is er een leuk terras met een speeltuintje.</h2>
                    <h2>Ook is er de mogelijkheid voor het organiseren van kleine feestjes.</h2>
                </section>
            </main>

        </>
    );
};

export default Home;