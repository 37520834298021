import React from 'react';
import Layout from "../components/layout/layout";

const Menu = () => {
    const warme_dranken = [
        {"item": "Thee (Zwart, Groen, Rozebottel of Munt)", "price": 2.60},
        {"item": "Koffie", "price": 2.60},
        {"item": "Koffie décaféiné", "price": 2.60},
        {"item": "Cappuccino", "price": 3.50},
        {"item": "Chocolademelk", "price": 3.00},
        {"item": "Irish Coffee (Whisky)", "price": 7.50},
        {"item": "Italian Coffee (Amaretto)", "price": 7.50},
        {"item": "French Coffee (Cognac)", "price": 7.50},
        {"item": "Parisian Coffee (Grand Marnier)", "price": 7.50}
        ]
    const frisdranken = [
        {"item": "Coca-Cola", "price": 2.60},
        {"item": "Coca-Cola Zero", "price": 2.60},
        {"item": "Sinaasappelsap", "price": 2.60},
        {"item": "Tomatensap", "price": 2.60},
        {"item": "Tönissteiner (Sinaas, Citroen of Vruchtenkorf)", "price": 2.60},
        {"item": "Schweppes Tonic", "price": 2.60},
        {"item": "S.Pellegrino 50 cl", "price": 3.50},
        {"item": "Acqua Panna 50 cl", "price": 3.50}
        ]
        const aperitieven =[
        {"item": "Sherry (Dry Medium)", "price": 5.00},
        {"item": "Porto (Wit of Rood)", "price": 5.00},
        {"item": "Martini (Bianco of Rosso)", "price": 5.00},
        {"item": "Campari Orange", "price": 7.50}
        ]
        const bieren = [
        {"item": "Stella", "price": 3.00},
        {"item": "Jupiler (alcoholvrij)", "price": 3.00},
        {"item": "Hoegaarden", "price": 3.00},
        {"item": "Kriek Lindemans", "price": 3.50},
        {"item": "Geuze", "price": 3.50},
        {"item": "Leffe (Blond of Bruin)", "price": 4.50},
        {"item": "Leffe Tripple", "price": 4.50},
        {"item": "Duvel", "price": 4.90},
        {"item": "Kasteel ( Blond of Bruin )", "price": 4.90},
        {"item": "Kasteel ( Tripel )", "price": 4.90}
        ]
    const wijnen = [
            {"item": "Huiswijn (Wit, Rood of Rosè) 50cl Fles", "price": 20.00},
            {"item": "Huiswijn (Wit, Rood of Rosè) Glas", "price": 4.50}
        ]
    const schuimwijn = [
        {"item": "Cava Fles", "price": 30.00},
        {"item": "Cava Glas", "price": 6.00},
        {"item": "Proseco Fles", "price": 30.00},
        {"item": "Proseco Glas", "price": 6.00},
        {"item": "Champagne", "price": 80.00}
        ]
    const likeuren_en_sterke_dranken = [
              {"item": "Cognac", "price": 7.50},
              {"item": "Calvados", "price": 7.50},
              {"item": "Grand Marnier", "price": 7.50},
              {"item": "Cointreau", "price": 7.50},
              {"item": "Whisky", "price": 7.50},
              {"item": "Mandarine Napoleon", "price": 7.50},
              {"item": "Amaretto", "price": 6.50},
              {"item": "Baileys", "price": 6.50}
            ]
    const voorgerechten = [
              {"item": "Kroket met Zeebrugse grijze garnalen (1stuk)", "price": 9.00},
              {"item": "Kroketten met Zeebrugse grijze garnalen (2stuks)", "price": 15.50},
              {"item": "Kaaskroket (1stuk)", "price": 8.00},
              {"item": "Kaaskroketten (2stuks)", "price": 14.00},
              {"item": "Duo van kaas - en garnaalkroketten", "price": 15.50},
              {"item": "Scampi‘s met look (8stuks)", "price": 15.50},
              {"item": "Bord gerookte zalm", "price": 15.50},
              {"item": "Dagsoep", "price": 5.50}
            ]
    const hoofdgerechten = [
        {"item": "Tagliatelli met twee soorten zalm en room", "price": 19.50},
        {"item": "Tagliatelli met scampi’s en courgette", "price": 19.50},
        {"item": "Salade met gerookte zalm", "price": 21.50},
        {"item": "Salade met warme geitenkaas", "price": 19.50},
        {"item": "Rundsteak met frieten en salade", "price": 24.00},
        {"item": "Entrecote met frieten en salade", "price": 27.00},
        {"item": "Hamburger, saus pesto, parmesan, pignon de pin,olijven", "price": 18.00},
        {"item": "Kipfilet met frieten of kroketten,salade en saus naar Keuze", "price": 20.00},
        {"item": "Zalmsteak met frieten of kroketten , salade en saus naar keuze", "price": 28.00}
    ]
    const desserts = [
                {"item": "Tiramisu van speculaas", "price": 8.00},
                {"item": "Appeltaart met ijs", "price": 7.50},
                {"item": "Chocoladetaart", "price": 7.50},
                {"item": "Pannenkoeken met suiker(2stuks)", "price": 7.50},
                {"item": "Pannenkoeken met cofituur(2stuks)", "price": 7.50},
                {"item": "Pannenkoeken Mikado(2stuks)", "price": 7.50},
                {"item": "Moelleux au chocolat", "price": 10.00},
                {"item": "IJsroom Vanille,Mokka,Chocolade,Pistache,Aardbei Coupe naar keuze(1 bol)", "price": 2.60},
                {"item": "IJsroom Vanille,Mokka,Chocolade,Pistache,Aardbei Coupe naar keuze(2 bollen)", "price": 5.00},
                {"item": "Dame blanche(2 bollen)", "price": 6.50},
                {"item": "+ Extra bol", "price": 2.50},
                {"item": "+ Slagroom", "price": 2.50}
                ]

    return (
        <>
            <Layout/>
            <main className="main">

                <section className="menu"/> 
                    <div className="top_title">
                        <h1>Menu</h1>
                    </div>
                    <div className="category">
                        <h2>Warme Dranken</h2>
                        <ul>
                            {warme_dranken.map((item, index) => (
                                <li key={index}>
                                <div>
                                    <span className='item'>{item.item}</span>
                                    <span className='price'>€ {item.price}</span>
                                </div>
                            </li>
                            ))}
                        </ul>

                        <h2>Frisdranken</h2>
                        <ul>
                            {frisdranken.map((item, index) => (
                                <li key={index}>
                                    <div>
                                        <span className='item'>{item.item}</span>
                                        <span className='price'>€ {item.price}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <h2>Aperitieven</h2>
                        <ul>
                            {aperitieven.map((item, index) => (
                                <li key={index}>
                                    <div>
                                        <span className='item'>{item.item}</span>
                                        <span className='price'>€ {item.price}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <h2>Bieren</h2>
                        <ul>
                            {bieren.map((item, index) => (
                                <li key={index}>
                                    <div>
                                        <span className='item'>{item.item}</span>
                                        <span className='price'>€ {item.price}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <h2>Wijnen</h2>
                        <ul>
                            {wijnen.map((item, index) => (
                                <li key={index}>
                                    <div>
                                        <span className='item'>{item.item}</span>
                                        <span className='price'>€ {item.price}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <h2>Schuimwijn</h2>
                        <ul>
                            {schuimwijn.map((item, index) => (
                                <li key={index}>
                                    <div>
                                        <span className='item'>{item.item}</span>
                                        <span className='price'>€ {item.price}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <h2>Likeuren en Sterke Dranken</h2>
                        <ul>
                            {likeuren_en_sterke_dranken.map((item, index) => (
                                <li key={index}>
                                    <div>
                                        <span className='item'>{item.item}</span>
                                        <span className='price'>€ {item.price}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <h2>Voorgerechten</h2>
                        <ul>
                            {voorgerechten.map((item, index) => (
                                <li key={index}>
                                    <div>
                                        <span className='item'>{item.item}</span>
                                        <span className='price'>€ {item.price}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <h2>Hoofdgerechten</h2>
                        <ul>
                            {hoofdgerechten.map((item, index) => (
                                <li key={index}>
                                    <div>
                                        <span className='item'>{item.item}</span>
                                        <span className='price'>€ {item.price}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <h2>Desserts</h2>
                        <ul>
                            {desserts.map((item, index) => (
                                <li key={index}>
                                    <div>
                                        <span className='item'>{item.item}</span>
                                        <span className='price'>€ {item.price}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
            </main>
        </>
    );
};

export default Menu;