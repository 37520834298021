import React from 'react'
import Layout from '../components/layout/layout'

const Contact = () => {
  return (
    <>
      <Layout />
      {/*<main className="main">*/}
      <main className='main-contact'>
        <div className='container_contact'>
          <div className='wrap-contact'>
            <h1>Contact</h1>
            <div className='nav-header_bottom'>
              <a
                href='https://www.facebook.com/TerKorbekeRestaurant/'
                target='_blank'
                rel='noreferrer'>
                <svg
                  version='1.1'
                  id='Layer_1'
                  xmlns='http://www.w3.org/2000/svg'
                  height='20'
                  viewBox='0 0 14.9 32'>
                  <path
                    d='M14.9,10.4h-5V7.1c0-1.2,0.8-1.5,1.4-1.5c0.6,0,3.5,0,3.5,0V0.1l-4.9,0c-5.4,0-6.7,4.1-6.7,6.7v3.6H0.2V16h3.1
	c0,7.2,0,15.9,0,15.9h6.6c0,0,0-8.8,0-15.9h4.4L14.9,10.4z'
                    fill='#ffae1b'></path>
                </svg>
              </a>
              <br />
              <span>Contact:</span>
              <br />
              <span>Oudebaan 106</span>
              <br />
              <span>3360 Bierbeek</span>
              <br />
              <a href="tel: +32 499 35 97 79">+32 499 35 97 79</a><br/>
              <a href='mailto: info@terkorbeke-restaurant.be'>info@terkorbeke-restaurant.be</a>
            </div>
          </div>
        </div>
        {/*</main>*/}
      </main>
    </>
  )
}

export default Contact
