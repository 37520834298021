import React, { memo } from 'react';
import Layout from "../components/layout/layout";

const days = [
  {
    name: "Maandag",
    frenchName: "Lundi",
    englishName: "Monday",
    soup: "Knoflookroomsoep",
    frenchSoup: "Soupe crème d'ail",
    englishSoup: "Garlic cream soup",
    mainCourse: "Entrecôte minute met pepersaus en frieten",
    frenchMainCourse: "Entrecôte minute avec sauce poivre et frites",
    englishMainCourse: "Entrecôte minute with pepper sauce and fries"
  },
  {
    name: "Dinsdag",
    frenchName: "Mardi",
    englishName: "Tuesday",
    soup: "Seldersoep",
    frenchSoup: "Soupe de céleri",
    englishSoup: "Celery soup",
    mainCourse: "Varkensspareribs met barbecuesaus, salade en aardappelpuree",
    frenchMainCourse: "Spareribs de porc avec sauce barbecue, salade et purée",
    englishMainCourse: "Pork ribs with barbecue sauce, salad, and mashed potatoes"
  },
  {
    name: "Woensdag",
    frenchName: "Mercredi",
    englishName: "Wednesday",
    soup: "Pastinaaksoep",
    frenchSoup: "Soupe de panais",
    englishSoup: "Parsnip soup",
    mainCourse: "Kipschnitzel met pittige saus, salade en frieten",
    frenchMainCourse: "Escalope de poulet avec sauce piquante, salade et frites",
    englishMainCourse: "Chicken schnitzel with spicy sauce, salad, and fries"
  },
  {
    name: "Donderdag",
    frenchName: "Jeudi",
    englishName: "Thursday",
    soup: "Paprikasoep",
    frenchSoup: "Soupe de poivrons",
    englishSoup: "Paprika soup",
    mainCourse: "Kippenreepjes met groenten en rijst",
    frenchMainCourse: "Émincé de poulet avec légumes et riz",
    englishMainCourse: "Chicken strips with vegetables and rice"
  },
  {
    name: "Vrijdag",
    frenchName: "Vendredi",
    englishName: "Friday",
    soup: "Erwtensoep",
    frenchSoup: "Soupe de petits pois",
    englishSoup: "Pea soup",
    mainCourse: "Zeebaarsfilet met kappertjessaus en aardappelpuree",
    frenchMainCourse: "Filet de bar avec sauce au beurre aux câpres et purée",
    englishMainCourse: "Sea bass fillet with caper butter sauce and mashed potatoes"
  },
  {
    name: "Zaterdag",
    frenchName: "Samedi",
    englishName: "Saturday",
    soup: "Courgettesoep",
    frenchSoup: "Soupe de courgettes",
    englishSoup: "Zucchini soup",
    mainCourse: "Varkenskotelet met mosterdsaus en kroketten",
    frenchMainCourse: "Côte de porc avec sauce moutarde et croquettes",
    englishMainCourse: "Pork chop with mustard sauce and croquettes",
    dessert: "Appeltaart",
    frenchDessert: "Tarte aux pommes",
    englishDessert: "Apple pie"
  },
  {
    name: "Zondag",
    frenchName: "Dimanche",
    englishName: "Sunday",
    soup: "Champignonroomsoep",
    frenchSoup: "Crème de champignons",
    englishSoup: "Mushroom cream soup",
    mainCourse: "Kalfsblanquette met frieten",
    frenchMainCourse: "Blanquette de veau et frites",
    englishMainCourse: "Veal Blanquette with Fries"
  }
];
const alternativeMenu = {
  "dutch": "Kalfsblanquette met frietjes",
  "french": "Blanquette de veau avec frites",
  "english": "Veal Blanquette with fries"
};
const Lunch = () => {
  return (
      <>
          <Layout/>
          <main className="main">
              <section className='lunch'/>
              <div className="best_price">
                  <img
                      loading='lazy'
                      src="/images/best_price_2.webp"
                      alt="Lunch"
                  />
              </div>
              <div className="top_title">
                  <h1>Lunch</h1>
              </div>



              {/* Основное меню */}
              <div className='lunch_wrap'>
                  <div className='lunch_box'>
                      <h3>16/09 - 22/09</h3>
                      <h3>11:30 – 15:00</h3>
                              {/* Альтернативное меню */}
              <div className="alternative_menu">
                  <h3>Alternatief gerecht voor deze week:</h3>
                  <p>{alternativeMenu.dutch} - {alternativeMenu.french} - {alternativeMenu.english}</p>
              </div>      
                      {days.map((day, index) => (
                          <div key={index}>
                              <h4>{day.name} – {day.frenchName} – {day.englishName}</h4>
                              <p>{day.soup} - {day.frenchSoup} - {day.englishSoup}</p>
                              <p>{day.mainCourse}</p>
                              <p>{day.frenchMainCourse}</p>
                              <p>{day.englishMainCourse}</p>
                              {day.dessert && (
                                  <p>{day.dessert} - {day.frenchDessert} - {day.englishDessert}</p>
                              )}
                          </div>
                      ))}
                  </div>
              </div>
          </main>
      </>
  );
};


export default memo(Lunch);
